// Works
export const works = [
  {
    title: "PSworks",
    subtitle: "Agency",
    category: "Wordpress Development",
    tags: ["XD", "JS", "CSS3", "ACF", "CPT UI", "SEO", "WPO"],
    cover: "./img/projects/psworks_02.webp",
    link: "https://www.psworks.es/",
    class: "full",
  },
  {
    title: "Socialisssima",
    subtitle: "Agency",
    category: "Wordpress Design & Development",
    tags: ["JS", "CSS3", "ACF", "CPT UI", "SEO", "WPO"],
    cover: "./img/projects/socialisssima_2024.webp",
    link: "https://socialisssima.com/",
    class: "half",
  },
  {
    title: "Amazon Future Engineer",
    subtitle: "Consulting",
    category: "Wordpress UX / UI",
    tags: ["UI", "UX", "Heuristic Analysis"],
    cover: "./img/projects/amazon_fe.webp",
    link: "https://www.amazonfutureengineer.com/",
    blog_link: "https://juanfalibene.com/writing/#/case-study/109",
    class: "vertical-wide",
  },
  /*{
    title: "Pollinators and Prairies",
    subtitle: "Landing Page",
    category: "Wordpress Design & Development",
    tags: ["Wireframes", "UI", "UX", "ACF", "CSS3", "JS", "WPO"],
    cover: "./img/projects/pp_texas.webp",
    link: "https://pollinatorsandprairies.org/",
    class: "square",
  },*/
  {
    title: "Martina Orska",
    subtitle: "Photographer",
    category: "Wordpress Development",
    tags: ["PHP", "ACF", "CPT UI", "JS", "CSS3", "SEO", "WPO"],
    cover: "./img/projects/martina_orska.webp",
    link: "https://martinaorska.com/",
    class: "vertical",
  },
  {
    title: "Víctor Segura Campins",
    subtitle: "Writer",
    category: "Wordpress Development",
    tags: ["ACF", "JS", "CSS3", "SEO", "WPO"],
    cover: "./img/projects/victor_segura_2024.webp",
    link: "https://victorseguracampins.com",
    class: "vertical",
    blog_link: "https://juanfalibene.com/writing/#/case-study/8",
  },
  {
    title: "Rocio Ley",
    subtitle: "Set Designer",
    category: "Wordpress Development",
    tags: ["ACF", "JS", "CSS3", "CPT UI", "SEO", "WPO"],
    cover: "./img/projects/03_smoda.webp",
    link: "https://rocioley.com",
    class: "vertical",
    blog_link: "https://juanfalibene.com/writing/#/case-study/11",
  },
];
// Projects
export const projects = [
  {
    title: "Juan Falibene Writing",
    subtitle: "WP Headless",
    category: "WP & FrontEnd Development",
    tags: [
      "Wordpress",
      "WP REST API",
      "JWT Auth",
      "ACF",
      "CPT UI",
      "React.js",
      "CSS3",
      "HTML5",
      "SEO",
    ],
    cover: "./img/projects/project_blog_writing.webp",
    link: "https://juanfalibene.com/writing",
    class: "half",
    blog_link:
      "https://juanfalibene.com/writing/#/post/id=28&category=idea-de-proyecto",
  },
  {
    title: "Web Dev Tree",
    subtitle: "WP Theme",
    category: "WP Development",
    tags: [
      "Wordpress",
      "PHP",
      "CMS",
      "ACF",
      "CPT UI",
      "Templates",
      "CSS3",
      "HTML5",
      "JS Vanilla",
    ],
    cover: "./img/projects/project-wp-theme-dev.webp",
    link: "https://links.juanfalibene.com/dev-tree/",
    class: "vertical-wide",
    github_link: "https://github.com/juanfalibene/web-dev-tree",
    blog_link:
      "https://juanfalibene.com/writing/#/post/id=235&category=idea-de-proyecto",
  },
  {
    title: "Music Portfolio",
    subtitle: "Navigation",
    category: "FrontEnd Development",
    tags: ["React", "React Router", "React Hooks"],
    cover: "./img/projects/project02.webp",
    link: "https://music.juanfalibene.com/",
    github_link: "https://github.com/juanfalibene/react-music-portfolio",
    class: "vertical-wide",
  },
  {
    title: "Discava!",
    subtitle: "Vinyl Search APP",
    category: "FrontEnd Development",
    tags: ["HTML5", "CSS3", "JS Vanilla", "Discogs API"],
    cover: "./img/projects/discava-js-cifo-2023.webp",
    link: "http://dev.juanfalibene.com/discava/index.html",
    class: "vertical-wide",
  },
  {
    title: "Song Of The Day",
    subtitle: "Swiper JS",
    category: "FrontEnd Development",
    tags: ["HTML5", "CSS3", "JS Vanilla", "SwiperJS", "Spotify"],
    cover: "./img/projects/song_of_the_day_app.webp",
    link: "https://dev.juanfalibene.com/sotd",
    github_link: "https://github.com/juanfalibene/01_swipe",
    class: "vertical-wide",
  },
  {
    title: "Daily News",
    subtitle: "React Web APP",
    category: "FrontEnd Development",
    tags: [
      "React",
      "Redux",
      "Google API",
      "G News API",
      "Axios",
      "Material UI",
    ],
    cover: "./img/projects/daily-news-app-g.webp",
    link: "https://dev.juanfalibene.com/daily-news",
    github_link: "https://github.com/juanfalibene/daily-news",
    class: "half",
  },
  {
    title: "Simple Projects",
    subtitle: "Collection",
    category: "FrontEnd Development",
    tags: ["JS Vanilla", "React", "CSS3", "HTML5"],
    cover: "./img/projects/project05.webp",
    link: "https://juanfalibene.com/writing/#/challenges",
    github_link: "https://github.com/juanfalibene/",
    class: "vertical-wide",
  },
];
// Clients
export const clients = [
  {
    name: "Pablo Martinez Herrera",
    link: "https://www.linkedin.com/in/pablo-mart%C3%ADnez-herrera-93529345/",
    since: 2019,
  },
  {
    name: "Socialisssima",
    link: "https://socialisssima.com",
    since: 2020,
  },
  {
    name: "PS Works",
    link: "https://psworks.es",
    since: 2022,
  },
  {
    name: "Partida Continua",
    link: "https://partidacontinua.com/",
    since: 2023,
  },
];

export const hard_skills = [
  {
    skill: "HTML5",
    image: "./img/skills/html-5.svg",
  },
  {
    skill: "CCS3",
    image: "./img/skills/css-3.svg",
  },
  {
    skill: "JS",
    image: "./img/skills/js.svg",
  },
  {
    skill: "React",
    image: "./img/skills/react.svg",
  },
  {
    skill: "Wordpress",
    image: "./img/skills/wordpress.svg",
  },
  {
    skill: "PHP",
    image: "./img/skills/php_02.svg",
  },
  /*{
    skill: "Github",
    image: "./img/skills/github-mark-white.svg",
  },*/
];

export const soft_skills = [
  {
    skill: "Problem Solving",
  },
  {
    skill: "Creativity",
  },
  {
    skill: "Effective Communication",
  },
  {
    skill: "Detail Orientation",
  },
  {
    skill: "Team Collaboration",
  },
  {
    skill: "Time Management",
  },
];
// About
export const about = [
  {
    name: "Juan Falibene",
    text: "Based in Barcelona",
    profession: "Wordpress & Frontend Developer",
    image: "./img/juanfalibene_profile_hover.webp",
    imagePic: "./img/juanfalibene_profile_pic.webp",
    resume_page: "./resume/index.html",
    about_page: "./about.html",
    bio_excerpt:
      "WordPress & Front-End Developer passionate about crafting tailored digital experiences. Skilled in translating initial concepts into fully functional, user-focused solutions. Dedicated to delivering high-quality projects that balance creativity, performance, and client objectives.",
    bio: "A detail-oriented freelance web developer with a proven ability to create visually engaging and highly functional websites. Combining formal education with self-taught expertise, I specialize in delivering tailored solutions that balance intuitive design and technical precision, always aligned with the unique goals of each client.",
  },
];
// Sections
export const section_description = [
  {
    section: "Works",
    description:
      "Over the past 5 years, I have specialized in designing and developing WordPress websites for agencies and individual clients. I provide complete solutions, including design, development, support, and knowledge transfer to ensure every project’s success.",
  },
  {
    section: "Blog",
    description:
      "I invite you to explore my <a href='https://juanfalibene.com/writing/' target='_blank' rel='noopener noreferrer'>blog</a>, where I share more about my journey and expand on my work. You'll find insights into my latest projects, reflections on web development, and an archive of my work's.",
  },
  {
    section: "Projects",
    description:
      "These projects are part of my ongoing journey to learn and master front-end technologies. Through them, I demonstrate my commitment to developing skills and knowledge in this field.",
  },
];
// Blog Links
export const blog_links = [
  {
    title: "WP Headless Introduction",
    link: "https://juanfalibene.com/writing/#/post/id=28&category=idea-de-proyecto",
  },
  {
    title: "Connect WP Headless & React.js",
    link: "https://juanfalibene.com/writing/#/post/id=235&category=idea-de-proyecto",
  },
  {
    title: "WP Theme Development from Scratch",
    link: "https://juanfalibene.com/writing/#/post/id=655&category=idea-de-proyecto",
  },
  {
    title: "Solving Everyday Challenges",
    link: "https://juanfalibene.com/writing/#/case-studies",
  },
  {
    title: "My Online Journey",
    link: "https://juanfalibene.com/writing/#/post/id=522&category=historia",
  },
];
// About Links
export const about_links = [
  {
    name: "Linkedin",
    link: "https://www.linkedin.com/in/juanfalibene/",
    category: "professional",
  },
  {
    name: "GitHub",
    link: "https://github.com/juanfalibene",
    category: "professional",
  },
  {
    name: "Behance",
    link: "https://www.behance.net/juanfalibene",
    category: "professional",
  },
  {
    name: "Blog",
    link: "https://juanfalibene.com/writing/",
    category: "personal",
  },
  {
    name: "Music",
    link: "https://music.juanfalibene.com",
    category: "personal",
  },
  {
    name: "Mail",
    link: "mailto:juanignaciofalibene@gmail.com",
    category: "personal",
  },
  {
    name: "©Juan Falibene_24",
    category: "credit",
  },
  {
    name: "Barcelona",
    category: "credit",
  },
];
// Certificates
export const certificates = [
  {
    name: "Desenvolupament web Front End CIFO L'Hospitalet ",
    link: "https://juanfalibene.com/resume/downloads/Certificat-417219-Y8901768T.PDF",
    year: 2023,
  },
  {
    name: "English Reading(C2) & Listening(B2) EF SET",
    link: "https://juanfalibene.com/resume/downloads/EF_SET_Certificate.pdf",
    year: 2024,
  },
  {
    name: "CSS: Diseño web responsive",
    link: "https://juanfalibene.com/resume/downloads/1673896108444.png",
    year: 2023,
  },
  {
    name: "Diseño de interfaz (UI)",
    link: "https://juanfalibene.com/resume/downloads/1673478097062.png",
    year: 2023,
  },
  {
    name: "Experiencia de usuario (UX)",
    link: "https://juanfalibene.com/resume/downloads/1673444494312.png",
    year: 2023,
  },
];
// Resume
export const resume_urls = [
  {
    name: "Resume Online",
    link: "https://juanfalibene.com/resume/index.html",
    action: "View",
  },
  {
    name: "Resume PDF",
    link: "https://juanfalibene.com/resume/downloads/Juan_Ignacio_Falibene_CV_2024_ES.pdf",
    action: "Download",
  },
];
